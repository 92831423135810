var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    {
      staticClass: "mew-component--wallet-footer pa-0",
      attrs: { color: "bgWalletBlock", app: "", inset: "", absolute: "" },
    },
    [
      _c(
        "v-container",
        {
          staticClass:
            "d-flex align-center justify-center wallet-content-container pa-md-6",
          attrs: { fluid: "" },
        },
        [
          _c(
            "v-row",
            { staticClass: "align-center justify-space-between" },
            [
              _c("v-col", { attrs: { cols: "12", md: "7" } }, [
                _c("div", { staticClass: "mb-0 text-center text-md-left" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("footer.copyright", {
                          year: new Date().getFullYear(),
                        })
                      ) +
                      " "
                  ),
                  !_vm.isOfflineApp
                    ? _c(
                        "a",
                        {
                          staticClass: "textDark--text text-decoration--none",
                          attrs: {
                            href: "https://www.coingecko.com/en",
                            target: "_blank",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("footer.coingecko")) + " ")]
                      )
                    : _c("span", [_vm._v(_vm._s(_vm.$t("footer.coingecko")))]),
                ]),
              ]),
              _c(
                "v-col",
                {
                  staticClass: "pb-0 pb-md-2",
                  attrs: {
                    cols: "12",
                    md: "4",
                    lg: "3",
                    order: "first",
                    "order-md": "last",
                  },
                },
                [
                  _c("div", {
                    staticClass:
                      "d-flex align-center justify-center justify-md-end textDark--text",
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }